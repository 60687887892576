<template>
  <div v-if="loading">
    <slot name="shared"></slot>
    <slot name="loading">
      <spinner />
    </slot>
  </div>
  <div v-else-if="errors.notFound">
    <slot name="shared"></slot>
    <slot name="not-found">
      <b-row class="w-100 h-100 text-center p-auto">
        <b-col md="4 mx-auto">
          <b-alert show variant="warning">
            <div class="alert-body">
              <span>{{ $t("errors.resource-not-found") }}</span>
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </slot>
  </div>
  <div v-else-if="errors.serverError">
    <slot name="shared"></slot>
    <slot name="server-error">
      <b-row class="w-100 h-100 text-center p-auto">
        <b-col md="4 mx-auto">
          <b-alert show variant="warning">
            <div class="alert-body">
              <span>{{ $t("errors.server-error") }}</span>
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </slot>
  </div>
  <div v-else>
    <slot name="shared"></slot>
    <slot name="default"></slot>
  </div>
</template>

<script>
import Spinner from "./Spinner.vue";
export default {
  components: { Spinner },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style>
</style>